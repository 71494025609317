import { render, staticRenderFns } from "./StoreSetting.vue?vue&type=template&id=60e7a481&scoped=true"
import script from "./StoreSetting.vue?vue&type=script&lang=js"
export * from "./StoreSetting.vue?vue&type=script&lang=js"
import style0 from "./StoreSetting.vue?vue&type=style&index=0&id=60e7a481&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60e7a481",
  null
  
)

export default component.exports