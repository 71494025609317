<template>
  <div>
    <v-row
      align="center"
      class="mb-4 bg-white">
      <v-col cols="auto">
        <h2 id="store-setting-title">
          Store Setting
        </h2>
      </v-col>

      <v-col cols="auto">
        <v-btn
          v-if="!edit"
          icon
          small
          color="secondary"
          :loading="loading"
          @click="edit = !edit">
          <v-icon>mdi-playlist-edit</v-icon>
        </v-btn>
        <v-btn
          v-else
          icon
          small
          color="secondary"
          :loading="loading"
          @click="edit = !edit">
          <v-icon>mdi-playlist-remove</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-form
      v-if="ready"
      ref="form"
      v-model="formValid"
      @submit.prevent="save()">
      <v-row class="setting-content bg-white">
        <v-col cols="6">
          <v-text-field
            v-model.trim="formData.defaultBrand"
            :rules="rules.required"
            :disabled="!edit"
            label="Default Brand" />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.trim="formData.orderPrefix"
            :rules="rules.required"
            :disabled="!edit"
            label="Order Prefix" />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model.trim="formData.linkBill"
            :rules="rules.required"
            :disabled="!edit"
            label="Link Bill URL" />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model.trim="formData.webUrl"
            :rules="rules.required"
            :disabled="!edit"
            label="WEB URL" />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model.trim="formData.logoUrl"
            :disabled="!edit"
            label="LOGO URL" />
        </v-col>
        <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col cols="12">
          <h3>Dispute Warehouse</h3>
        </v-col>
        <v-col
          cols="12"
          md="12">
          <v-select
            v-model="formData.disputeWarehouse"
            :rules="rules.required"
            label="Dispute Warehouse"
            item-text="label"
            :items="warehouses"
            :disabled="!edit"
            item-value="id" />
        </v-col>
        <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col cols="12">
          <h3>Order Setting</h3>
        </v-col>
        <v-col cols="12">
          <span>Order System Expire</span>
        </v-col>
        <v-col
          cols="6"
          md="1">
          <v-text-field
            v-model.number="formData.orderSetting.orderExpire.systemExpireAmount"
            :disabled="!edit"
            label="Time" />
        </v-col>
        <v-col
          cols="6"
          md="2">
          <v-select
            v-model="formData.orderSetting.orderExpire.systemExpireUnit"
            :rules="rules.required"
            label="Unit"
            :disabled="!edit"
            :items="['minute', 'hour']" />
        </v-col>
        <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col cols="12">
          <span>Order User Expire</span>
        </v-col>
        <v-col cols="1">
          <v-text-field
            v-model.number="formData.orderSetting.orderExpire.userExpireAmount"
            :disabled="!edit"
            label="Time" />
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="formData.orderSetting.orderExpire.userExpireUnit"
            :rules="rules.required"
            label="Unit"
            :disabled="!edit"
            :items="['minute', 'hour']" />
        </v-col>
        <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col cols="12">
          <h3>Print Receipt Setting</h3>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="formData.printReceiptSetting.logo1"
            :disabled="!edit"
            label="LOGO 1" />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="formData.printReceiptSetting.logo2"
            :disabled="!edit"
            label="LOGO 2" />
        </v-col>

        <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col cols="12">
          <h3>DHL Ecommerce Setting</h3>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model.trim="formData.dhlEcommerceSetting.dhlPrefix"
            :disabled="!edit"
            label="DHL Ecommerce Prefix" />
        </v-col>
        <v-col cols="8">
          <v-text-field
            v-model.trim="formData.dhlEcommerceSetting.filename"
            :disabled="!edit"
            label="Export file name" />
        </v-col>
        <v-col cols="12">
          <span>Pickup Warehouse</span>
        </v-col>
        <v-col cols="12">
          <v-row
            v-for="(val, index) of formData.dhlEcommerceSetting.pickupWarehouses"
            :key="'registrationNumbers-'+index">
            <v-col cols="6">
              <v-select
                v-model.number="formData.dhlEcommerceSetting.pickupWarehouses[index].id"
                :rules="rules.required"
                label="Warehouse"
                :disabled="!edit"
                item-text="label"
                :items="warehouses"
                item-value="id" />
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model.number="formData.dhlEcommerceSetting.pickupWarehouses[index].pickupId"
                :rules="rules.required"
                :disabled="!edit"
                label="Pickup ID" />
            </v-col>
            <v-col cols="1">
              <gw-icon-hover
                v-if="formData.dhlEcommerceSetting.pickupWarehouses.length > 1 && edit"
                class="remove-img"
                icon-name="mdi-delete"
                icon-hover="mdi-delete-empty"
                @click="formData.dhlEcommerceSetting.pickupWarehouses.splice(index, 1)" />
            </v-col>
          </v-row>
          <div
            v-show="edit"
            class="add-btn"
            @click="addDhlEcommercePickupWarehouses">
            +
          </div>
        </v-col>
        <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col cols="12">
          <h3>Mandrill Setting</h3>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.trim="formData.mandrillSetting.email"
            :rules="rules.required"
            :disabled="!edit"
            label="Email" />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.trim="formData.mandrillSetting.key"
            type="password"
            :disabled="!edit"
            label="Key" />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.trim="formData.mandrillSetting.name"
            :rules="rules.required"
            :disabled="!edit"
            label="Name" />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.trim="formData.mandrillSetting.subject"
            :rules="rules.required"
            :disabled="!edit"
            label="Subject" />
        </v-col>
        <v-col cols="12">
          <span>Mandrill Template</span>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.trim="formData.mandrillSetting.templates.createOrder.en"
            :rules="rules.required"
            :disabled="!edit"
            label="Create Order EN" />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.trim="formData.mandrillSetting.templates.createOrder.th"
            :rules="rules.required"
            :disabled="!edit"
            label="Create Order TH" />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.trim="formData.mandrillSetting.templates.deliveredOrder.en"
            :rules="rules.required"
            :disabled="!edit"
            label="Delivered Order EN" />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.trim="formData.mandrillSetting.templates.deliveredOrder.th"
            :rules="rules.required"
            :disabled="!edit"
            label="Delivered Order TH" />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.trim="formData.mandrillSetting.templates.paidOrder.en"
            :rules="rules.required"
            :disabled="!edit"
            label="Paid Order EN" />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.trim="formData.mandrillSetting.templates.paidOrder.th"
            :rules="rules.required"
            :disabled="!edit"
            label="Paid Order TH" />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.trim="formData.mandrillSetting.templates.sendOtp.en"
            :rules="rules.required"
            :disabled="!edit"
            label="Send OTP EN" />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.trim="formData.mandrillSetting.templates.sendOtp.th"
            :rules="rules.required"
            :disabled="!edit"
            label="Send OTP TH" />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.trim="formData.mandrillSetting.templates.clickAndCollectOrder.en"
            :rules="rules.required"
            :disabled="!edit"
            label="Click And Collect Order EN" />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.trim="formData.mandrillSetting.templates.clickAndCollectOrder.th"
            :rules="rules.required"
            :disabled="!edit"
            label="Click And Collect Order TH" />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.trim="formData.mandrillSetting.templates.clickToCollectOrder.en"
            :rules="rules.required"
            :disabled="!edit"
            label="Click To Collect Order EN" />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.trim="formData.mandrillSetting.templates.clickToCollectOrder.th"
            :rules="rules.required"
            :disabled="!edit"
            label="Click To Collect Order TH" />
        </v-col>
        <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col cols="12">
          <h3>Member Setting</h3>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="formData.memberSetting.vipSpending"
            :rules="rules.required"
            :disabled="!edit"
            label="Upgrade VIP Spending" />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.number="formData.memberSetting.vvipSpending"
            :rules="rules.required"
            :disabled="!edit"
            label="Upgrade VVIP Spending" />
        </v-col>
        <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col cols="12">
          <h3>Promotion Code Setting</h3>
        </v-col>
        <v-col cols="12">
          <span>Member Type <b>"Normal"</b> Send Promotion Code On Paid Order</span>
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="formData.promotionCodeSetting.sendPromotionOnPaidOrder.normal.active"
            label="Active"
            item-text="text"
            item-value="value"
            :items="[{ text: 'Active', value: true }, { text: 'Inactive', value: false }]"
            :disabled="!edit"
          />
        </v-col>
        <v-col cols="5">
          <v-text-field
            v-model.number="formData.promotionCodeSetting.sendPromotionOnPaidOrder.normal.promotionCodeDetail.maxPrice"
            :rules="rules.required"
            :disabled="!edit"
            label="Promotion Code Max Price" />
        </v-col>
        <v-col cols="5">
          <v-text-field
            v-model.number="formData.promotionCodeSetting.sendPromotionOnPaidOrder.normal.promotionCodeDetail.minPrice"
            :disabled="!edit"
            label="Promotion Code Min Price" />
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="formData.promotionCodeSetting.sendPromotionOnPaidOrder.normal.promotionCodeDetail.productTagIds.status"
            label="Product Tag Active"
            item-text="text"
            item-value="value"
            :items="[{ text: 'Active', value: true }, { text: 'Inactive', value: false }]"
            :disabled="!edit"
          />
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="formData.promotionCodeSetting.sendPromotionOnPaidOrder.normal.promotionCodeDetail.productTagIds.include"
            label="Include"
            item-text="text"
            item-value="value"
            :items="[{ text: 'YES', value: true }, { text: 'NO', value: false }]"
            :disabled="!edit"
          />
        </v-col>
        <v-col cols="7">
          <v-combobox
            v-model="formData.promotionCodeSetting.sendPromotionOnPaidOrder.normal.promotionCodeDetail.productTagIds.values"
            counter
            :disabled="!edit"
            multiple
          ></v-combobox>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="formData.promotionCodeSetting.sendPromotionOnPaidOrder.normal.message"
            :disabled="!edit"
            label="Message" />
        </v-col>
        <v-col cols="12">
          <span>Member Type <b>"VIP"</b> Send Promotion Code On Paid Order</span>
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="formData.promotionCodeSetting.sendPromotionOnPaidOrder.vip.active"
            label="Active"
            item-text="text"
            item-value="value"
            :items="[{ text: 'Active', value: true }, { text: 'Inactive', value: false }]"
            :disabled="!edit"
          />
        </v-col>
        <v-col cols="5">
          <v-text-field
            v-model.number="formData.promotionCodeSetting.sendPromotionOnPaidOrder.vip.promotionCodeDetail.maxPrice"
            :rules="rules.required"
            :disabled="!edit"
            label="Promotion Code Max Price" />
        </v-col>
        <v-col cols="5">
          <v-text-field
            v-model.number="formData.promotionCodeSetting.sendPromotionOnPaidOrder.vip.promotionCodeDetail.minPrice"
            :disabled="!edit"
            label="Promotion Code Min Price" />
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="formData.promotionCodeSetting.sendPromotionOnPaidOrder.vip.promotionCodeDetail.productTagIds.status"
            label="Product Tag Active"
            item-text="text"
            item-value="value"
            :items="[{ text: 'Active', value: true }, { text: 'Inactive', value: false }]"
            :disabled="!edit"
          />
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="formData.promotionCodeSetting.sendPromotionOnPaidOrder.vip.promotionCodeDetail.productTagIds.include"
            label="Include"
            item-text="text"
            item-value="value"
            :items="[{ text: 'YES', value: true }, { text: 'NO', value: false }]"
            :disabled="!edit"
          />
        </v-col>
        <v-col cols="7">
          <v-combobox
            v-model="formData.promotionCodeSetting.sendPromotionOnPaidOrder.vip.promotionCodeDetail.productTagIds.values"
            counter
            :disabled="!edit"
            multiple
          ></v-combobox>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="formData.promotionCodeSetting.sendPromotionOnPaidOrder.vip.message"
            :disabled="!edit"
            label="Message" />
        </v-col>
        <v-col cols="12">
          <span>Member Type <b>"VVIP"</b> Send Promotion Code On Paid Order</span>
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="formData.promotionCodeSetting.sendPromotionOnPaidOrder.vvip.active"
            label="Active"
            item-text="text"
            item-value="value"
            :items="[{ text: 'Active', value: true }, { text: 'Inactive', value: false }]"
            :disabled="!edit"
          />
        </v-col>
        <v-col cols="5">
          <v-text-field
            v-model.number="formData.promotionCodeSetting.sendPromotionOnPaidOrder.vvip.promotionCodeDetail.maxPrice"
            :rules="rules.required"
            :disabled="!edit"
            label="Promotion Code Max Price" />
        </v-col>
        <v-col cols="5">
          <v-text-field
            v-model.number="formData.promotionCodeSetting.sendPromotionOnPaidOrder.vvip.promotionCodeDetail.minPrice"
            :disabled="!edit"
            label="Promotion Code Min Price" />
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="formData.promotionCodeSetting.sendPromotionOnPaidOrder.vvip.promotionCodeDetail.productTagIds.status"
            label="Product Tag Active"
            item-text="text"
            item-value="value"
            :items="[{ text: 'Active', value: true }, { text: 'Inactive', value: false }]"
            :disabled="!edit"
          />
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="formData.promotionCodeSetting.sendPromotionOnPaidOrder.vvip.promotionCodeDetail.productTagIds.include"
            label="Include"
            item-text="text"
            item-value="value"
            :items="[{ text: 'YES', value: true }, { text: 'NO', value: false }]"
            :disabled="!edit"
          />
        </v-col>
        <v-col cols="7">
          <v-combobox
            v-model="formData.promotionCodeSetting.sendPromotionOnPaidOrder.vvip.promotionCodeDetail.productTagIds.values"
            counter
            :disabled="!edit"
            multiple
          ></v-combobox>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="formData.promotionCodeSetting.sendPromotionOnPaidOrder.vvip.message"
            :disabled="!edit"
            label="Message" />
        </v-col>
        <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col cols="12">
          <h3>SMS Setting</h3>
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="formData.smsSetting.active"
            label="Enable"
            item-text="text"
            item-value="value"
            :items="[{ text: 'Active', value: true }, { text: 'Inactive', value: false }]"
            :disabled="!edit"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model.trim="formData.smsSetting.senderName"
            :rules="rules.required"
            :disabled="!edit"
            label="SMS Sender Name" />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model.trim="formData.smsSetting.upgradeVIPMessage"
            :rules="rules.required"
            :disabled="!edit"
            label="Upgrade VIP Message" />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model.trim="formData.smsSetting.upgradeVVIPMessage"
            :rules="rules.required"
            :disabled="!edit"
            label="Upgrade VVIP Message" />
        </v-col>
        <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col cols="12">
          <h3>Move Product Warehouse</h3>
        </v-col>
        <v-col cols="12">
          <v-row
            v-for="(val, index) of formData.moveStockWarehouse"
            :key="'moveStockWarehouse-'+index">
            <v-col cols="6">
              <v-select
                v-model="formData.moveStockWarehouse[index]"
                :rules="rules.required"
                label="Warehouse"
                item-text="label"
                :items="warehouses"
                :disabled="!edit"
                return-object />
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model.number="formData.moveStockWarehouse[index].percent"
                :rules="rules.number"
                :disabled="!edit"
                label="Percent" />
            </v-col>
            <v-col cols="2">
              <gw-icon-hover
                v-if="formData.moveStockWarehouse.length > 1 && edit"
                class="remove-img"
                icon-name="mdi-delete"
                icon-hover="mdi-delete-empty"
                @click="formData.moveStockWarehouse.splice(index, 1)" />
            </v-col>
          </v-row>
          <div
            v-show="edit"
            class="add-btn"
            @click="addMoveStockWarehouse">
            +
          </div>
        </v-col>
        <v-col cols="12">
          <v-divider />
        </v-col>
      </v-row>
      <v-row>
        <v-col v-show="edit">
          <v-btn
            color="primary"
            :loading="loading"
            type="submit">
            SAVE
          </v-btn>
          <v-btn
            color="secondary"
            class="ml-2"
            :loading="loading"
            @click="reset()">
            Reset
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import StoreProvider from '@/resources/StoreProvider'
import WarehouseProvider from '@/resources/WarehouseProvider'
import { mapActions } from 'vuex'

const WarehouseService = new WarehouseProvider()
const StoreService = new StoreProvider()

export default {
  data () {
    return {
      formData: null,
      edit: false,
      loading: false,
      formValid: false,
      ready: false,
      warehouses: [],
      rules: {
        required: [
          (v) => !!v || 'This field is required'
        ],
        number: [
        (v) => !/^\d+&/.test(v) || 'This field must be number'
        ]
      }
    }
  },
  computed: {
    filteredWarehouse () {
      return this.warehouses.filter((wh) => !this.formData.moveStockWarehouse.some((mw) => mw.id === wh.id))
    }
  },
  async created () {
    this.loading = true
    await this.getWarehouse()
    await this.getStoreSetting()
    this.ready = true
    this.loading = false
  },
  methods: {
    ...mapActions({
      initStoreSetting: 'Store/initStoreSetting'
    }),
    async save () {
      this.$refs.form.validate()
      if (!this.formValid) {
        document.getElementById('store-setting-title').scrollIntoView({ behavior: 'smooth' })
        return
      }

      try {
        this.loading = true
        const { data } = await StoreService.updateStoreSetting(this.formData)
        if (data) {
          this.getStoreSetting()
          this.initStoreSetting()
          this.edit = false
        }
      } catch (error) {
        this.setErrorPage(error.code)
      } finally {
        this.loading = false
      }
    },
    reset () {
      this.getStoreSetting()
    },
    async getWarehouse () {
      try {
        const { data } = await WarehouseService.getWarehouse()
        this.warehouses = data.map((branch) => ({
          id: branch.id,
          label: branch.name,
          percent: 0
        }))
      } catch (error) {
        this.store.dispatch('Components/setSnackbar', {
          value: true,
          message: `Error code ${error.code} : ${error.message}`,
          type: 'error'
        })
      }
    },
    addDhlEcommercePickupWarehouses () {
      this.formData.dhlEcommerceSetting.pickupWarehouses.push({
        id: null,
        pickupId: null
      })
    },
    addMoveStockWarehouse () {
      this.formData.moveStockWarehouse.push({
        id: null,
        label: '',
        percent: null
      })
    },
    async getStoreSetting () {
      try {
        this.loading = true
        const { data } = await StoreService.getStoreSetting()
        this.formData = data
        this.formData.moveStockWarehouse = data.moveStockWarehouse.map((mswh) => ({
          id: mswh.id,
          label: (this.warehouses.find((wh) => wh.id === mswh.id))?.label || 'Unknow',
          percent: mswh.percent
        }))
      } catch (error) {
        console.error('getStore: ', error)
        this.setErrorPage(error.code)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="css" scoped>
.setting-content{
  height: calc(100vh - 200px);
  overflow: auto;
}
.add-btn {
  font-size: 24px;
  cursor: pointer;
  width: fit-content;
  margin: auto;
}
</style>
